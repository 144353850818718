import {
  Create,
  Edit,
  Show,
  List,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  NumberInput,
  ImageInput,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceInput,
  NumberField,
  SelectInput,
  ImageField,
  BooleanField,
  BooleanInput,
} from 'react-admin';
import Pagination from 'components/Pagination';
import { defaultPerPage } from 'utils/helpers';

const listFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <ReferenceInput source="network_id" reference="networks">
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

export const InventorySpaceList = props => (
  <List
    {...props}
    perPage={defaultPerPage}
    pagination={<Pagination />}
    bulkActionButtons={false}
    filters={listFilters}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />

      <ReferenceField source="network_id" reference="networks">
        <TextField source="name" />
      </ReferenceField>

      <TextField source="name" />
      <NumberField source="order" />

      <BooleanField source="has_url" />
      <BooleanField source="has_views" />

      <ImageField source="icon_url" label="Icon" sortable={false} />
    </Datagrid>
  </List>
);

export const InventorySpaceEdit = props => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm>
      <ReferenceInput source="network_id" reference="networks" isRequired>
        <SelectInput optionText="name" />
      </ReferenceInput>

      <TextInput source="name" required />
      <TextInput source="description" multiline required rows={10} />
      <BooleanInput source="active" />
      <NumberInput source="order" />

      <TextInput source="caption_name" />

      <BooleanInput source="fan" helperText="Is this fan specific inventory" />

      <BooleanInput
        source="has_caption"
        helperText="Present the caption slider and textarea to advertisers when adding to cart"
      />

      <BooleanInput
        source="has_url"
        helperText="Requires URL input from advertiser when adding to cart"
      />

      <BooleanInput
        source="has_views"
        helperText="Requires views upload from influencer before delivering a todo"
      />

      <BooleanInput
        source="has_media"
        helperText="Requires image upload from influencer when setting up inventory"
      />

      <BooleanInput
        source="has_assets"
        helperText="Displays the option of adding assets for advertisers when adding to cart"
      />

      <BooleanInput
        source="show_asset_choices"
        helperText="Displays the option of adding assets for advertisers when adding to cart"
      />

      <BooleanInput
        source="has_quantity"
        helperText="Enables the quantity slider for advertisers when adding to cart"
      />
      <BooleanInput
        source="has_variable_quantity"
        helperText="Replaces the quantity slider for advertisers when adding to cart with custom range (digital signature)"
      />

      <BooleanInput
        source="has_content"
        helperText="For influencer created content, changes labels when delivering todo screenshots"
      />

      <BooleanInput
        source="offer"
        helperText="Allows the influencer to specify min and max offer price when setting up inventory and requires the advertiser to specify an offer price when adding to cart"
      />

      <BooleanInput source="license" helperText="Flag for digital signatures" />

      <NumberInput
        source="min_quantity"
        helperText="Overrides the default (1) min quantity for advertisers when adding to cart"
      />
      <NumberInput
        source="max_quantity"
        helperText="Overrides the default (20) max quantity for advertisers when adding to cart"
      />

      <NumberInput
        source="default_quantity"
        helperText="Overrides the default (1) default quantity for advertisers when adding to cart"
      />

      <NumberInput
        source="min_variable_quantity"
        helperText="Overrides the default (1) min variable quantity for advertisers when adding to cart"
      />

      <NumberInput
        source="max_variable_quantity"
        helperText="Overrides the default (20) max variable quantity for advertisers when adding to cart"
      />

      <NumberInput
        source="default_variable_quantity"
        helperText="Overrides the default (1) default variable quantity for advertisers when adding to cart"
      />

      <ImageField source="icon_url" label="Current Icon" />

      <ImageInput source="icon" accept="image/*, svg+xml">
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export const InventorySpaceCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" required />
      <TextInput source="description" multiline required rows={10} />
      <BooleanInput source="active" />

      <NumberInput source="order" required />

      <ReferenceInput source="network_id" reference="networks" required>
        <SelectInput optionText="name" />
      </ReferenceInput>

      <BooleanInput source="fan" helperText="Is this fan specific inventory" />

      <BooleanInput
        source="has_caption"
        helperText="Present the caption slider and textarea to advertisers when adding to cart"
      />

      <BooleanInput
        source="has_url"
        helperText="Requires URL input from advertiser when adding to cart"
      />

      <BooleanInput
        source="has_views"
        helperText="Requires views upload from influencer before delivering a todo"
      />

      <BooleanInput
        source="has_media"
        helperText="Requires image upload from influencer when setting up inventory"
      />

      <BooleanInput
        source="has_assets"
        helperText="Displays the option of adding assets for advertisers when adding to cart"
      />

      <BooleanInput
        source="has_quantity"
        helperText="Enables the quantity slider for advertisers when adding to cart"
      />
      <BooleanInput
        source="has_variable_quantity"
        helperText="Replaces the quantity slider for advertisers when adding to cart with custom range (digital signature)"
      />

      <BooleanInput
        source="has_content"
        helperText="For influencer created content, changes labels when delivering todo screenshots"
      />

      <BooleanInput
        source="offer"
        helperText="Allows the influencer to specify min and max offer price when setting up inventory and requires the advertiser to specify an offer price when adding to cart"
      />

      <BooleanInput source="license" helperText="Flag for digital signatures" />

      <NumberInput
        source="min_quantity"
        helperText="Overrides the default (1) min quantity for advertisers when adding to cart"
      />
      <NumberInput
        source="max_quantity"
        helperText="Overrides the default (20) max quantity for advertisers when adding to cart"
      />

      <NumberInput
        source="default_quantity"
        helperText="Overrides the default (1) default quantity for advertisers when adding to cart"
      />

      <NumberInput
        source="min_variable_quantity"
        helperText="Overrides the default (1) min variable quantity for advertisers when adding to cart"
      />

      <NumberInput
        source="max_variable_quantity"
        helperText="Overrides the default (20) max variable quantity for advertisers when adding to cart"
      />

      <NumberInput
        source="default_variable_quantity"
        helperText="Overrides the default (1) default variable quantity for advertisers when adding to cart"
      />

      <ImageInput source="icon" accept="image/*, svg+xml">
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

export const InventorySpaceShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <NumberField source="order" />

      <ReferenceField source="network_id" reference="networks">
        <TextField source="name" />
      </ReferenceField>

      <BooleanField source="has_url" />
      <BooleanField source="has_views" />

      <ImageField source="icon_url" label="Icon" />
    </SimpleShowLayout>
  </Show>
);
