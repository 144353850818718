import { liveTimeUnitChoices } from 'choices';
import Pagination from 'components/Pagination';
import {
  Create,
  Edit,
  List,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  BooleanField,
  SelectInput,
  ReferenceField,
  NumberField,
  ReferenceInput,
  NumberInput,
  BooleanInput,
  AutocompleteInput,
} from 'react-admin';
import { defaultPerPage } from 'utils/helpers';

const InventorySpaceField = ({ record }) => `${record?.network?.name} ${record?.name}`;

const listFilters = [<TextInput label="Search" source="q" alwaysOn />];

export const LiveTimeOptionList = props => (
  <List {...props} perPage={defaultPerPage} pagination={<Pagination />} filters={listFilters}>
    <Datagrid rowClick="edit">
      <ReferenceField source="inventory_space_id" reference="inventory_spaces">
        <InventorySpaceField />
      </ReferenceField>

      <NumberField source="duration" />
      <TextField source="unit" />
      <NumberField source="price_preset_ratio" />

      <BooleanField source="default" />
    </Datagrid>
  </List>
);

export const LiveTimeOptionEdit = props => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm redirect="list">
      <ReferenceInput
        source="inventory_space_id"
        reference="inventory_spaces"
        filterToQuery={searchText => ({
          q: searchText,
        })}
      >
        <AutocompleteInput
          fullWidth
          optionText={record => `${record?.network?.name} ${record?.name}`}
        />
      </ReferenceInput>
      <BooleanInput source="active" />
      <SelectInput source="unit" choices={liveTimeUnitChoices} />
      <NumberInput source="duration" />
      <NumberInput source="price_preset_ratio" min={0.1} max={1} />
      <BooleanInput source="default" />
    </SimpleForm>
  </Edit>
);

export const LiveTimeOptionCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <ReferenceInput
        source="inventory_space_id"
        reference="inventory_spaces"
        filterToQuery={searchText => ({
          q: searchText,
        })}
      >
        <AutocompleteInput
          fullWidth
          optionText={record => `${record?.network?.name} ${record?.name}`}
        />
      </ReferenceInput>

      <BooleanInput source="active" />
      <SelectInput source="unit" choices={liveTimeUnitChoices} />
      <NumberInput source="duration" />
      <NumberInput source="price_preset_ratio" min={0.1} max={1} />

      <BooleanInput source="default" />
    </SimpleForm>
  </Create>
);
