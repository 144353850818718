import { announcementKindOptions } from 'choices';
import Pagination from 'components/Pagination';
import {
  Create,
  Edit,
  Show,
  List,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  Datagrid,
  TextField,
  DateField,
  SelectInput,
  BooleanInput,
} from 'react-admin';

export const AnnouncementList = props => (
  <List {...props} pagination={<Pagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="kind" />
      <TextField source="description" />
      <TextField source="onboarding" />
      <TextField source="url" />
      <DateField source="created_at" />
    </Datagrid>
  </List>
);

export const AnnouncementCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="/announcements">
      <TextInput source="title" />
      <TextInput multiline minRows={4} source="description" />
      <TextInput source="url" />
      <BooleanInput source="onboarding" />
      <SelectInput source="kind" choices={announcementKindOptions} />
    </SimpleForm>
  </Create>
);

export const AnnouncementEdit = props => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm redirect="/announcements">
      <TextInput source="title" />
      <TextInput multiline minRows={4} source="description" />
      <TextInput source="url" />
      <BooleanInput source="onboarding" />
      <SelectInput source="kind" choices={announcementKindOptions} />
    </SimpleForm>
  </Edit>
);

export const AnnouncementShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="description" />
      <TextField source="onboarding" />
      <TextField source="url" />
    </SimpleShowLayout>
  </Show>
);
